/* * {
  padding: 0px;
  margin: 0px;
  overflow-x: hidden;
  box-sizing: border-box;
  position: relative !important;
} */

.adminContant {
  width: 100% !important;
  margin: 2% auto !important;
}

td {
  /* color: #B3B9BC !important; */
  font-size: 15px !important;
}

.adminformlayout {
  text-align: left !important;
  padding-bottom: 5px !important;
}

.adminContent-1 {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.footer-text {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;

  /* Black */

  color: #414146;
}

.search-bar {
  background-color: #fff !important;
  color: #FF7534 !important;
  border-right: none;
}

.search-input {
  border-left: none;
  padding-left: 0px;
  border-radius: 0rem 0.375rem 0.375rem 0rem !important;
}

.search-input:focus {
  box-shadow: none !important;
  border-color: #ced4da !important;
}

.search-input:active {
  box-shadow: none !important;
  border-color: #ced4da !important;
}

.content-header {
  justify-content: flex-end;
  align-items: flex-start;
}

@media screen and (max-width: 768px) {
  .content-header {
    justify-content: left;
    align-items: flex-start;
  }
}

.chart {
  width: 100%;
  height: 400px;
  margin-top: 1rem;
}

.chart .chartjs-render-monitor {
  font-size: 10px; /* Adjust the font size of the chart labels */
}
