.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}
::-webkit-scrollbar-thumb {
  background: grey;
  border-radius: 10px;
  opacity: 0.5;
}
::-webkit-scrollbar-thumb:hover {
  background: grey;
  opacity: 0.5;
}

.details-row {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.details-label {
  flex-basis: 300px;
  text-align: left;  
  font-weight: bold;
}

.details-value {
  flex-grow: 1;
  text-align: left;
}

.navabar-menu{
  position: fixed !important;
    top: 0;
    right: 0;
    left: 0;
    z-index: 1030;
    background: #fff;
    box-shadow: 0px 5px 21px -5px #CDD1E1;
}

.navar-logo{
  margin-left: 30px;
}

.navar-profile{
  margin-right: 38px;
}

.bell-icon{
  margin-right: 20px;
  height: 25px;
  width: 25px;
}

.bell-count{
  position: absolute;
    top: 10px;
    right: 100px;
    background: #FF0000;
    color: #fff;
    border-radius: 50%;
    height: 20px;
    width: 20px;
    text-align: center;
    font-size: 12px;
    line-height: 20px;
}

.dashboardImage{
  width: 50px;
  height: 50px !important;
}



