.page-action {
    border-radius: 4px !important;
    border: 1px solid #F0F1F2 !important;
    font-style: normal;
    font-weight: 400;
    font-size: 14px !important;
    color: #5D5F5F !important;
    letter-spacing: 0.02em;
}

.page-link {
    color: #fff;
    text-decoration: none;
    background-color: transparent !important;
    border: none !important;
    font-size: 14px !important;
}
.page-link:active {
    color: #fff;
    text-decoration: none;
    background-color: transparent !important;
    border: none !important;
    font-size: 14px !important;
}
.page-link:focus {
    color: #fff;
    text-decoration: none;
    background-color: transparent !important;
    border: none !important;
    box-shadow: none !important;
    font-size: 14px !important;
}
.page-link:hover {
    color: #fff;
    text-decoration: none;
    background-color: transparent !important;
    border: none !important;
    font-size: 14px !important;
}

.page-items {
    background-color: #FF7534 !important;
    border-radius: 4px !important;
    color: #fff !important;
    margin-right: 10px;
    margin-left: 10px;
    font-size: 14px !important;
}

span {
    color: #5D5F5F;
    font-size: 14px !important;
}