/* .adminpageloginpage {
  height: 100vh !important;
} */
.logindiv {
  width: 70% !important;
  margin: 20px auto !important;
}
.adminloginbanner {
  position: relative;
  /* top: 0%; */
  height: 450px;
  width: 450px;
}
.login-hover:hover{
  background-color: #3f3e91 !important;
}
@media only screen and (min-width: 701px) and (max-width: 1200px) {
  .logindiv {
    width: 100% !important;
  }
  .adminloginbanner {
    height: 350px;
    width: 350px;
  }
}

@media only screen and (min-width: 300px) and (max-width: 600px) {
  .logindiv {
    width: 100% !important;
  }
  .adminloginbanner {
    height: 300px;
    width: 300px;
  }
}