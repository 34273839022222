@font-face {
    font-family: "Inter-Regular";
    src: url("../Assets/fonts/Inter/Inter-Regular.ttf") format("truetype");
    font-style: normal;
    font-weight: 400;
    font-display: swap;
}

@font-face {
    font-family: "Inter-Bold";
    src: url("../Assets/fonts/Inter/Inter-Bold.ttf");
    font-style: bold;
    font-weight: 500;
    font-display: swap;
}